import React from 'react';
import { Root } from './style';

import RawHTML from 'components/Common/RawHTML';

import pagination from 'pagination';

const Paginator = ({
  path,
  pageIndex,
  paramName,
  perPage,
  totalCount,
  setPage,
}) => {
  const paginator = new pagination.SearchPaginator({
    prelink: path,
    current: pageIndex,
    rowsPerPage: perPage,
    totalResult: totalCount,
    slashSeparator: !paramName,
    pageParamName: paramName || '',
    translator: (string) => {
      switch (string) {
        case 'PREVIOUS':
          return 'Back';
        case 'NEXT':
          return 'Next';
        default:
      }
    },
  });

  const onClick = (event) => {
    if (setPage && event.target.tagName === 'A') {
      event.preventDefault();

      const href = event.target.getAttribute('href');
      const page = parseInt(href.match(/\d+$/)[0]);
      setPage(page);
      window.history.pushState({}, '', href);
    }
  };

  const html = paginator.render().replaceAll('//', '/');

  return (
    <Root className="pagination" onClick={onClick}>
      <RawHTML html={html} />
    </Root>
  );
};

export default Paginator;
